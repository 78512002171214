export const tableColumns = [
	{
		model: 'OplseasonId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'OplseasonNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplseasonNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplseasonNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplseasonNameDe',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplseasonNameEn',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OplseasonNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DateFrom',
		i18n: 'periodfromto',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'IsBasic',
		i18n: 'prefilled',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'Order',
		i18n: 'order',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
];
