var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-page", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isLoaded,
        expression: "isLoaded",
      },
    ],
    attrs: {
      tableName: _vm.$options.name,
      columns: _vm.columns,
      filterModel: _vm.filterModel,
      headerTitle: "seasonsinobject",
      tooltipMsg: "pmanualofseason",
      requestFunction: _vm.requestFunction,
      defaultOrderBy: { column: "OplseasonId", ascending: 0 },
      modelId: "OplseasonId",
    },
    on: {
      addNewRecord: function ($event) {
        return _vm.redirectToDetail()
      },
      editRecord: function ($event) {
        return _vm.redirectToDetail($event)
      },
      filterChanged: function ($event) {
        return _vm.handleFilter($event)
      },
      removeFilters: function ($event) {
        return _vm.removeAllFilters($event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "DateFrom",
        fn: function (list) {
          return [
            _vm._v(
              " " +
                _vm._s(_vm._f("date")(list.row.DateFrom)) +
                " - " +
                _vm._s(_vm._f("date")(list.row.DateTo)) +
                " "
            ),
          ]
        },
      },
      {
        key: "IsBasic",
        fn: function (list) {
          return [
            _c("boolean-display", { attrs: { boolValue: list.row.IsBasic } }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }